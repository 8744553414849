import React from "react";
import PropTypes from "prop-types";
import './InformationModal.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function InformationModal(props) {
  const {
    isOpen,
    onClose,
    headerText,
    bodyText,
    onClick,
    buttonText,
    isLoading,
  } = props;

  return (
    <div className={isOpen ? "modal open" : "modal"}>
      <div className="modal-overlay" onClick={() => onClose()} />
      <div className="modal-content">
        {isLoading ? (
          <div className="modal-loading">
            <FontAwesomeIcon icon={faSpinner} pulse size="3x" />
            <p>Loading...</p>
          </div>
        ) : (
          <>
            <div className="modal-header">
              <h2>{headerText}</h2>
            </div>
            <div className="modal-body">
              <p>{bodyText}</p>
              {props.children}
              <div className="modal-button">
                <button type="button" onClick={onClick}>{buttonText}</button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

InformationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  bodyText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default InformationModal;
