import React, { useState } from "react";
import "./RootContainer.css";
import GamesContainer from "../GamesContainer/GamesContainer";
import NavContainer from "../NavContainer/NavContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

function RootContainer() {
  const [isNavOpen, setIsNavOpen] = useState(true);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className="container">
      <div className={`sidebar ${isNavOpen ? "" : "sidebar-hidden"}`}>
        <NavContainer></NavContainer>
      </div>
      <div className={`sidebar-controller ${isNavOpen ? "" : "sidebar-hidden"}`} onClick={toggleNav}>
        {isNavOpen ? (
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        ) : (
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        )}
      </div>
      <div className={`content ${isNavOpen ? "" : "sidebar-hidden"}`}>
        <GamesContainer></GamesContainer>
      </div>
    </div>
  );
}

export default RootContainer;
