import React from "react";
import PropTypes from "prop-types";
import './FormModal.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function FormModal(props) {
  const {
    isOpen,
    onClose,
    headerText,
    formFields,
    formData,
    onChange,
    onSubmit,
    submitButtonText,
    isLoading,
  } = props;

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <div className={isOpen ? "modal open" : "modal"}>
      <div className="modal-overlay" onClick={() => onClose()} />
      <div className="modal-content">
        {isLoading ? (
          <div className="modal-loading">
            <FontAwesomeIcon icon={faSpinner} pulse size="3x" />
            <p>Loading...</p>
          </div>
        ) : (
          <>
            <div className="modal-header">
              <h2>{headerText}</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                {formFields.map((field) => (
                  <div key={field.name} className="form-field">
                    <label htmlFor={field.name}>{field.label}</label>
                    <input
                      type={field.type}
                      id={field.name}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={onChange}
                    />
                  </div>
                ))}
                <div className="form-field form-actions">
                  <button type="submit">{submitButtonText}</button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

FormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.oneOf(["text", "number", "email"])
    })
  ),
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default FormModal;
