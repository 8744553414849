import { React } from 'react';
import PropTypes from "prop-types";
import './Games.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

function Games(props) {
  const {
    games,
    deleteGameHandler,
    updateGameHandler,
    addGameHandler,
   } = props;

  return (
    <>
      <div className="games-list">
        <h3>Games</h3>
        {games.map((game, index) => {
          return (
            <div className="game" key={index}>
              <p>{game.entity1} {game.entity1Score} - {game.entity2Score} {game.entity2}</p>
              <FontAwesomeIcon className="modify-icon" icon={faTrash} onClick={() => deleteGameHandler(game)}/>
              <FontAwesomeIcon className="modify-icon" icon={faEdit} onClick={() => updateGameHandler(game)}/>
            </div>
          )
        })}
      </div>
      <button onClick={addGameHandler}>Add Game</button>
    </>
  );
}

Games.propTypes = {
  games: PropTypes.array.isRequired,
  deleteGameHandler: PropTypes.func.isRequired,
  updateGameHandler: PropTypes.func.isRequired,
  addGameHandler: PropTypes.func.isRequired,
};

export default Games;
