import './styles/modern-normalize.css';
import './styles/styles.css';
import './styles/utils.css';
import RootContainer from './app/components/containerComponents/RootContainer/RootContainer';

function App() {
  return (
    <div className="App">
      {/* <GamesContainer></GamesContainer> */}
      <RootContainer></RootContainer>
    </div>
  );
}

export default App;
