let URLEAGUES_BACKEND_BASE_URL = 'http://localhost:8080/v1/api';

if ( process.env.REACT_APP_URLEAGUES_BACKEND_BASE_URL ) {
  URLEAGUES_BACKEND_BASE_URL = process.env.REACT_APP_URLEAGUES_BACKEND_BASE_URL;
}

let CORS_HEADERS = {
  'Access-Control-Allow-Origin': 'localhost:8080',
  'Access-Control-Allow-Methods': '*',
  'Access-Control-Allow-Headers': '*',
};

if ( process.env.REACT_APP_CORS_HOST ) {
  CORS_HEADERS["Access-Control-Allow-Origin"] = process.env.REACT_APP_CORS_HOST;
}

let JSON_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};


export {
  URLEAGUES_BACKEND_BASE_URL,
  CORS_HEADERS,
  JSON_HEADERS,
};
