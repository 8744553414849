import { createSlice } from '@reduxjs/toolkit';

export const GamesSlice = createSlice({
  name: 'games',
  initialState: {
    games: [],
  },
  reducers: {
    set: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.games = action.payload;
    },
    add: (state, action) => {
      state.games = [...state.games, action.payload];
    },
    remove: (state, action) => {
      console.log(action.payload);
      state.games = state.games.filter(game => game.id !== action.payload);
    },
    update: (state, action) => {
      state.games = state.games.map(game => {
        if (game.id === action.payload.id) {
          return action.payload;
        }
        return game;
      });
    }
  },
})

// Action creators are generated for each case reducer function
export const { set, add, remove, update } = GamesSlice.actions

export default GamesSlice.reducer
