import { React, useEffect, useState, useRef } from 'react';
import './GamesContainer.css'
import { useSelector, useDispatch } from 'react-redux';
import { URLEAGUES_BACKEND_BASE_URL, CORS_HEADERS, JSON_HEADERS } from '../../../networkLayer/networkConstants';
import { set, add, remove, update } from '../../../stateManagement/GamesSlice';
import Games from '../../viewComponents/Games/Games';
import FormModal from '../../viewComponents/Modals/FormModal/FormModal';
import InformationModal from '../../viewComponents/Modals/InformationModal/InformationModal';
import { ModalTypes } from '../../../constants/constants';



export function GamesContainer() {
  const games = useSelector((state) => {
    return state.gamesTable.games}
  );
  const dispatch = useDispatch();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    entity1: "",
    entity1Score: "",
    entity2: "",
    entity2Score: ""
  });

  const gameRef = useRef(null);

  const headersValues = {
    ...CORS_HEADERS,
    ...JSON_HEADERS,
  }
  const headers = new Headers(headersValues);
  const url = new URL(URLEAGUES_BACKEND_BASE_URL + '/games');
  const formFields = [
    { label: "Player 1", name: "entity1", type: "text" },
    { label: "Player 1 Score", name: "entity1Score", type: "number" },
    { label: "Player 2", name: "entity2", type: "text" },
    { label: "Player 2 Score", name: "entity2Score", type: "number" }
  ];

  useEffect(() => {
    const fetchOptions = {
      url: url,
      options: {
        headers: headers,
        method: 'GET'
      },
      jsonSuccessCallback: json => {
        console.log(json.games);
        dispatch(set(json.games));
      },
      errorCallback: () => console.error("API Request Failed"),
    };

    fetchData(fetchOptions);
// eslint-disable-next-line
  }, []);

  const fetchData = (fetchOptions) => {
    fetch(fetchOptions.url, fetchOptions.options)
      .then(response => response.json())
      .then(json => setTimeout(() => fetchOptions.jsonSuccessCallback(json), 1500))
      .catch(fetchOptions.errorCallback);
  };

  const clearFormFields = () => {
    // Clear the form fields
    setFormData({
      entity1: "",
      entity1Score: "",
      entity2: "",
      entity2Score: ""
    });
  };

  const handleChange = (event) => {
    let inputType = event.target.attributes['type'].nodeValue;
    let value = inputType === "number" ? event.target.valueAsNumber : event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: value
    });
  };

  const closeModal = (type) => {
    setIsLoading(false);
    switch(type) {
      case ModalTypes.ADD:
        setShowAddModal(false);
        break;
      case ModalTypes.DELETE:
        setShowDeleteModal(false);
        break;
      case ModalTypes.UPDATE:
        clearFormFields();
        setShowUpdateModal(false);
        break;
      default:
        console.log("Modal type not defined");
    }
  }

  const openModal = (type, game) => {
    switch(type) {
      case ModalTypes.ADD:
        setShowAddModal(true);
        break;
      case ModalTypes.DELETE:
        gameRef.current = game;
        setShowDeleteModal(true);
        break;
      case ModalTypes.UPDATE:
        setFormData(game);
        setShowUpdateModal(true);
        break;
      default:
        console.log("Modal type not defined");
    }
  }

  const addGame = () => {
    // Do something with the form data here
    console.log(formData);
    setIsLoading(true);

    const fetchOptions = {
      url: url,
      options: {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(formData),
      },
      jsonSuccessCallback: json => {
        console.log(json);
        dispatch(add(json));
        closeModal(ModalTypes.ADD);
      },
      errorCallback: () => {
        console.error("API Request Failed");
        closeModal(ModalTypes.ADD);
      },
    };

    fetchData(fetchOptions);

    clearFormFields();
  };

  const deleteGame = () => {
    setIsLoading(true);

    const fetchOptions = {
      url: url + '/' + gameRef.current.id,
      options: {
        headers: headers,
        method: 'DELETE',
      },
      jsonSuccessCallback: json => {
        console.log(json);
        if (json.status === "Deleted game successfully") {
          dispatch(remove(gameRef.current.id))
        }
        closeModal(ModalTypes.DELETE);
      },
      errorCallback: () => {
        console.log("API Request Failed");
        closeModal(ModalTypes.DELETE);
      },
    };

    fetchData(fetchOptions);
  };

  const updateGame = () => {
    setIsLoading(true);

    const fetchOptions = {
      url: url + '/' + formData.id,
      options: {
        headers: headers,
        method: 'PUT',
        body: JSON.stringify(formData),
      },
      jsonSuccessCallback: json => {
        if (json.id === formData.id) {
          dispatch(update(json))
        }
        closeModal(ModalTypes.UPDATE);
      },
      errorCallback: () => {
        console.log("API Request Failed");
        closeModal(ModalTypes.UPDATE);
      },
    };

    fetchData(fetchOptions);
  };

  return (
    <div className="games-container">
      <Games
        games={games}
        deleteGameHandler={game => openModal(ModalTypes.DELETE, game)}
        updateGameHandler={game => openModal(ModalTypes.UPDATE, game)}
        addGameHandler={() => openModal(ModalTypes.ADD)}
      />
      <InformationModal
        isOpen={showDeleteModal}
        onClose={() => closeModal(ModalTypes.DELETE)}
        headerText="Deleting Game"
        bodyText="Are you sure you want to delete this game?"
        onClick={deleteGame}
        buttonText="Delete Game"
        isLoading={isLoading}
      >
        {gameRef.current ? (<p>{gameRef.current.entity1} {gameRef.current.entity1Score} - {gameRef.current.entity2Score} {gameRef.current.entity2}</p>) : (<></>)}
      </InformationModal>
      <FormModal
        isOpen={showAddModal}
        onClose={() => closeModal(ModalTypes.ADD)}
        headerText="Add Game"
        formFields={formFields}
        formData={formData}
        onChange={handleChange}
        onSubmit={addGame}
        submitButtonText="Add"
        isLoading={isLoading}
      />
      <FormModal
        isOpen={showUpdateModal}
        onClose={() => closeModal(ModalTypes.UPDATE)}
        headerText="Update Game"
        formFields={formFields}
        formData={formData}
        onChange={handleChange}
        onSubmit={updateGame}
        submitButtonText="Update"
        isLoading={isLoading}
      />
    </div>
  );
}

export default GamesContainer;
