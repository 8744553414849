import { React } from 'react';
import './NavContainer.css';

function NavContainer(props) {

  return (
    <>
      <div className="nav-container">
        <h2>Panda Scores</h2>
      </div>
    </>
  );
}


export default NavContainer;
